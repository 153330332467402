import React from "react"
import {
  Details,
  Summary,
} from "@fuegokit/gatsby-theme-fuegodocs/src/components/Details"
import { ImageWrapper } from "@fuegokit/gatsby-theme-fuegodocs/src/components/ImageWrapper"
import { Image } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Image"
import styled from "styled-components"
import { Text, Stack, Heading, Link, themeGet } from "@fuegokit/react"

import ArticleLayout from "../components/ArticleLayout"

const GetStartedDesignAtlassian = () => {
  return (
    <ArticleLayout title="Get started with ADS Components">
      <Heading as="h1">Get started with ADS Components</Heading>
      <Stack size={3}>
        <StyledHeading as="h2">How to use</StyledHeading>
        <Text>
          To use{" "}
          <Link
            href="https://www.figma.com/file/XXvL4TCKpge80qTGSAzsoX/%E2%AD%90%EF%B8%8F-ADS-Components---Fuegokit?type=design&node-id=231-2&mode=design&t=D1OQzxcVfe7gfnmU-0"
            target="_blank"
          >
            ADS Components - Fuegokit
          </Link>{" "}
          in your project, locate the library and enable it from the Figma
          Assets tab.
        </Text>
        <Text>
          Use the Library finder under Assets in the left side navigation in
          Figma:
        </Text>

        <ImageWrapper>
          <Image
            src="https://github.com/mash312/fuegokit-imgs/assets/128430248/b1d8c04b-289e-446b-b4a9-4852fa44ae48"
            alt="Screenshot showing Libraries icon under Assets tab in Figma"
          />
        </ImageWrapper>
        <Text>
          Search for the ADS Components - Fuegokit library under Design Systems
          and enable it:
        </Text>
        <ImageWrapper>
          <Image
            src="https://github.com/mash312/fuegokit-imgs/assets/128430248/ec2a8c37-12f1-4a56-8153-29623fec9561"
            alt="Screenshot showing list of libraries in Libraries modal in Figma"
          />
        </ImageWrapper>
        <Text>You can now use the library! 🎉</Text>
      </Stack>
      <Stack size={3}>
        <StyledHeading as="h2">About</StyledHeading>
        <Details>
          <Summary>What is ADS Components - Fuegokit?</Summary>
          <Text>
            The Fuegokit ADS Components package is a design system library
            surfacing Atlassian components that consume the same set of design
            tokens that powers working code in Fuegokit libraries used in
            Appfire products.
          </Text>
        </Details>
        <Details>
          <Summary>
            How is ADS Components - Fuegokit different from the official
            Atlassian ADS Components library?
          </Summary>
          <Text>
            The Fuegokit ADS Components library leverages the same tokens as our
            design system code, meaning designers and developers can more easily
            collaborate while using the same source of truth.
          </Text>
        </Details>
      </Stack>
      <Stack size={3}>
        <StyledHeading as="h2">Support</StyledHeading>
        <Text>
          Need more info or want some help?{" "}
          <Link
            target="_blank"
            href="https://appfireworkspace.slack.com/archives/C024PR8ECCD"
          >
            Reach out in #design-systems Slack!
          </Link>
        </Text>
      </Stack>
    </ArticleLayout>
  )
}

const StyledHeading = styled(Heading)`
  font-size: ${themeGet("fontSizes.5")};
  margin-bottom: ${themeGet("space.2")};
`

export default GetStartedDesignAtlassian
